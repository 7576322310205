import { Component, OnInit, Inject, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'

@Component({
  selector: 'app-popup-dialog',
  templateUrl: './popup-dialog.component.html',
  styleUrls: ['./popup-dialog.component.scss']
})
export class PopupDialogComponent implements OnInit {

  constructor (
    public dialogRef: MatDialogRef<PopupDialogComponent>,
    public changeRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public message: any,) { }

  ngOnInit() {
  }

  event: EventEmitter<any> = new EventEmitter();

  onClick(): void {
    this.event.emit(true);
    this.dialogRef.close();
  }
}
