import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MessageDialogComponent } from './message-dialog.component';
import { PromptDialogComponent } from '../prompt-dialog/prompt-dialog.component';
import { CustomAlertDialogData, CustomConfirmationDialogData, CustomPromptnDialogData } from 'src/app/interfaces/custom-dialog-data';

@Injectable()
export class MessageDialogService {
	title = 'angular-message-dialog';
	confirmationCustomData: CustomConfirmationDialogData;
	promptCustomData: CustomPromptnDialogData;
	alertCustonData: CustomAlertDialogData;

	constructor(public dialog: MatDialog) {
		this.confirmationCustomData = {
			width: '450px',
			data: {
				title: 'Confirmação',
				text: 'Você quer alterar esse registro?',
				confirm: 'Sim',
				reject: 'Não',
			},
		};
		this.promptCustomData = {
			width: '500px',
			data: {
				title: 'Prompt',
				text: 'Você quer alterar esse registro?',
				request_text: false,
				confirm: 'Sim',
			},
		};
		this.alertCustonData = {
			width: '350px',
			data: {
				title: 'Alerta',
				text: 'Texto padrão de Alerta',
			},
		};
	}

	private set(obj: object, target: CustomConfirmationDialogData | CustomPromptnDialogData | CustomAlertDialogData) {
		for (let ind in obj) {
			if (target.hasOwnProperty(ind)) {
				if (typeof obj[ind] === 'object' && obj[ind] !== null && typeof target[ind] === 'object' && target[ind] !== null) {
					this.set(obj[ind], target[ind]);
				} else {
					target[ind] = obj[ind];
				}
			}
		}
	}

	alertDialog(text: string) {
		this.set(
			{
				data: {
					title: '',
					text: text,
				},
			},
			this.alertCustonData,
		);
		const dialogRef = this.dialog.open(MessageDialogComponent, this.alertCustonData);
		dialogRef.componentInstance.changeRef.markForCheck();
		return dialogRef.componentInstance.event;
	}

	customConfirmationDialog(customData: CustomConfirmationDialogData) {
		this.set(customData, this.confirmationCustomData);
		const dialogRef = this.dialog.open(MessageDialogComponent, this.confirmationCustomData);
		dialogRef.componentInstance.changeRef.markForCheck();
		return dialogRef.componentInstance.event;
	}

	customPromptDialog(customData: CustomPromptnDialogData) {
		this.set(customData, this.promptCustomData);
		const dialogRef = this.dialog.open(PromptDialogComponent, this.promptCustomData);
		dialogRef.componentInstance.changeRef.markForCheck();
		return dialogRef.componentInstance.event;
	}

	customAlertDialog(customData: CustomAlertDialogData) {
		this.set(customData, this.alertCustonData);
		const dialogRef = this.dialog.open(MessageDialogComponent, this.alertCustonData);
		dialogRef.componentInstance.changeRef.markForCheck();
		return dialogRef.componentInstance.event;
	}
}
