import { Http, Headers, RequestOptions } from "@angular/http";
import { map, catchError, timeout } from "rxjs/operators";
import { WebServiceResponseHandlerService } from "./responses/web-service-response-handler.service";
import { throwError } from 'rxjs';
import { WebServiceResponse } from '../interfaces/web-service-response.model';
import { AuthService } from '../_auth/auth.service';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { MessageDialogService } from './shared/message-dialog/message-dialog.service';

export class BaseServicePadrao {
    headers: Headers;
    app: AppComponent;
    options: RequestOptions;
    urlBase: string = sessionStorage.getItem("urlBase"); //http://127.0.0.1:8090/phoenix/api/";
    token: string;
    wsHandler: WebServiceResponseHandlerService = new WebServiceResponseHandlerService();



    constructor(private httpBase: Http, url: string) {
        //  console.log("URL BASE >>>")
        //  console.log(this.urlBase);
        this.urlBase = this.urlBase + url;
        // this.token = "";
        this.token = window.sessionStorage.getItem('token_type') + " " + window.sessionStorage.getItem('token');

        this.headers = new Headers(
            { 'Content-Type': 'application/json', 'Authorization': this.token });
        this.options = new RequestOptions({ headers: this.headers });
    }


    handleError(error: any) {
        let retorno: WebServiceResponse = new WebServiceResponse();

        retorno.object = JSON.parse(error._body);
        retorno.httpStatus = error.status;

        if ((retorno.httpStatus == 401 && retorno.object.error == "invalid_token")||
            retorno.httpStatus == 401 && retorno.object.error == "Unauthorized") {
            sessionStorage.removeItem("role")
            sessionStorage.removeItem("id");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("login");
            sessionStorage.removeItem("certified");
            sessionStorage.removeItem("perfil");
            sessionStorage.removeItem("name");
            sessionStorage.removeItem("menu");
            sessionStorage.removeItem("razao");
            sessionStorage.removeItem("reception");
            sessionStorage.removeItem("authority");
            sessionStorage.removeItem("accredited");
            sessionStorage.removeItem("currentUser");
            sessionStorage.removeItem("token_type");
            sessionStorage.removeItem("token_user");
            sessionStorage.removeItem("expires_in");
            window.location.href = window.location.pathname;
            //console.log("Logout requisitado por");
        }
        return throwError(error);
    }


    checkToken() {
        this.token = window.sessionStorage.getItem('token_type') + " " + window.sessionStorage.getItem('token');
        this.headers = new Headers(
            { 'Content-Type': 'application/json', 'Authorization': this.token });
        this.options = new RequestOptions({ headers: this.headers });
    }

    salvar(conteudo: any) {
        this.checkToken();
        return this.httpBase.post(this.urlBase+"/salvar", conteudo, this.options)
            .pipe(timeout(40000),
                map(dados => this.wsHandler.handle(dados)), catchError(this.handleError));
    }

    editar(conteudo: any) {
        this.checkToken();
        return this.httpBase.put(this.urlBase+"/salvar", conteudo, this.options)
            .pipe(timeout(40000),
                map(dados => this.wsHandler.handle(dados)), catchError(this.handleError));
    }

    deletar(codigo: number) {
        this.checkToken();
        return this.httpBase.delete(this.urlBase + "/" + codigo, this.options)
            .pipe(timeout(40000),
                map(dados => this.wsHandler.handle(dados)), catchError(this.handleError));
    }

    desabilitar(conteudo: any) {
        this.checkToken();
        return this.httpBase.post(this.urlBase+"/desabilitar", conteudo, this.options)
            .pipe(timeout(40000),
                map(dados => this.wsHandler.handle(dados)), catchError(this.handleError));
    }

    consultar() {
        this.checkToken();
        return this.httpBase.get(this.urlBase+"/", this.options)
            .pipe(timeout(40000),
                map(dados => this.wsHandler.handle(dados)), catchError(this.handleError));
    }

    consultarTodos() {
        this.checkToken();
        return this.httpBase.get(this.urlBase + "/", this.options)
            .pipe(timeout(40000),
                map(dados => this.wsHandler.handle(dados)), catchError(this.handleError));
    }

    consultarID(id: number) {
        this.checkToken();
        return this.httpBase.get(this.urlBase + "/consultar/" + id, this.options)
            .pipe(timeout(40000),
                map(dados => this.wsHandler.handle(dados)), catchError(this.handleError));
    }

    // consultarIntervalo(inicio: number, fim: number, filtro?: string) {
    //     this.checkToken();
    //     if(filtro){
    //       return this.httpBase.get(this.urlBase + "/intervalo/" + "/" + inicio + "/" + fim + "/" + filtro, this.options)
    //       .pipe( timeout(40000),
    //         map(dados =>  this.wsHandler.handle(dados) ),catchError(this.handleError));
    //     }else{
    //       return this.httpBase.get(this.urlBase + "/intervalo/" + "/" + inicio + "/" + fim, this.options)
    //       .pipe( timeout(40000),
    //         map(dados =>  this.wsHandler.handle(dados) ),catchError(this.handleError));
    //     }
    // }

    consultarIntervalo(inicio: number, fim: number) {
      this.checkToken();
      return this.httpBase.get(this.urlBase + "/intervalo/" + inicio + "/" + fim, this.options)
          .pipe(timeout(40000),
              map(dados => this.wsHandler.handle(dados)), catchError(this.handleError));
  }

    consultarIntervaloTipo(tipo: number, inicio: number, fim: number) {
        this.checkToken();
        return this.httpBase.get(this.urlBase + "/intervalo/" + tipo + "/" + inicio + "/" + fim, this.options)
            .pipe(timeout(40000),
                map(dados => this.wsHandler.handle(dados)), catchError(this.handleError));
    }

    consultarCPF(cpf: string, datanascimento: string) {
        return this.httpBase.get("https://api.nfse.io/NaturalPeople/Basicinfo/taxNumber/" + cpf + "/" + datanascimento, this.options)
            .map(dados => this.wsHandler.handle(dados), catchError(this.handleError));
    }

    consultarCPNJ(cnpj: string) {
        return this.httpBase.get("http://localhost:8091/projeto/innovatox/api/empresas/cnpj/" + cnpj, this.options)
            .map(dados => this.wsHandler.handle(dados), catchError(this.handleError));
    }

    consultarCEP(cep: string) {
        let headers = new Headers(
            { 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });
        return this.httpBase.get("https://viacep.com.br/ws/"+cep.replace(".","").replace("-","")+"/json", options)
            .map(dados => this.wsHandler.handle(dados), catchError(this.handleError));
    }
}
