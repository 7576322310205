import { Injectable } from "@angular/core";
import { MatDialog } from '@angular/material';
import { PopupDialogComponent } from './popup-dialog.component';

@Injectable()
export class PopupDialogService {

  title = 'angular-popup-dialog';

  constructor (public dialog: MatDialog) { }

  openDialog() {
    const dialogRef = this.dialog.open(PopupDialogComponent, {
    });

    dialogRef.componentInstance.changeRef.markForCheck();
    return dialogRef.componentInstance.event;
  }
}
