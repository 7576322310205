import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material';
import { ResetPasswordDialogComponent } from './reset-password-dialog.component';


@Injectable()
export class ResetPasswordDialogService {

    title = 'Recuperar dados do usuário';

    constructor(public dialog: MatDialog) {}


    openDialog(){
      const dialogRef = this.dialog.open(ResetPasswordDialogComponent, {
        width: '350px',
        data: "Voce quer alterar esse registro?"
      });

      /*dialogRef.afterClosed().subscribe(result => {
        if(result) {
          console.log('Sim clicked');
          console.log(result);
          // DO SOMETHING
          return result
        }
      });*/
      dialogRef.componentInstance.changeRef.markForCheck();
      return dialogRef.componentInstance.event;

    }
  
}