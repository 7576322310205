import { Component, OnInit, Inject, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { Voucher } from 'src/app/interfaces/vouchers';

@Component({
  selector: 'app-vouchers-dialog',
  templateUrl: './vouchers-dialog.component.html',
  styleUrls: ['./vouchers-dialog.component.scss']
})
export class VouchersDialogComponent implements OnInit {
  public dataSource: MatTableDataSource<Voucher>;
  displayedColumns: string[] = ['id','doador','cpf','finalidade','objetivo','dataCadastro'];
  event: EventEmitter<any> = new EventEmitter();

  constructor (
    public dialogRef: MatDialogRef<VouchersDialogComponent>,
    public changeRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: Voucher[],
  ) { }

  ngOnInit() {
        this.dataSource = new MatTableDataSource(this.data);
  }

  onClick(voucher: Voucher): void {
    this.event.emit(voucher);
    this.dialogRef.close();
  }
}
