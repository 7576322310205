import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from '@angular/material';
import { VouchersDialogComponent } from './vouchers-dialog.component';
import { Voucher } from "src/app/interfaces/vouchers";

@Injectable()
export class VouchersDialogService {

  title = 'angular-vouchers-dialog';

  constructor (public dialog: MatDialog) { }

  openDialog(lstVouchers: Voucher[]) {
    let dialogConfig: MatDialogConfig = {
      data: lstVouchers,
      width: "900px",
      maxHeight: "600px"
    }

    const dialogRef = this.dialog.open(VouchersDialogComponent, dialogConfig);

    dialogRef.componentInstance.changeRef.markForCheck();
    return dialogRef.componentInstance.event;
  }
}
