import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material';
//import { NOTIMP } from "dns";
import { ConfirmationDialogComponent } from './confirmation-dialog.component';


@Injectable()
export class ConfirmationDialogService {

  title = 'angular-confirmation-dialog';

  constructor(public dialog: MatDialog) { }



  openDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Você quer alterar esse registro?"
    });

    /*dialogRef.afterClosed().subscribe(result => {
      if(result) {
        console.log('Sim clicked');
        console.log(result);
        // DO SOMETHING
        return result
      }
    });*/
    dialogRef.componentInstance.changeRef.markForCheck();
    return dialogRef.componentInstance.event;

  }

  deleteDialog(text: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Você quer excluir " + text + "?"
    });

    /*dialogRef.afterClosed().subscribe(result => {
      if(result) {
        console.log('Sim clicked');
        console.log(result);
        // DO SOMETHING
        return result
      }
    });*/
    dialogRef.componentInstance.changeRef.markForCheck();
    return dialogRef.componentInstance.event;

  }

  confirmarDialog(dados: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      width: '450px',
      data: dados,
    });

    /*dialogRef.afterClosed().subscribe(result => {
      if(result) {
        console.log('Sim clicked');
        console.log(result);
        // DO SOMETHING
        return result
      }
    });*/
    dialogRef.componentInstance.changeRef.markForCheck();
    return dialogRef.componentInstance.event;
  }

  confirmarDialogPre(text: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: "Deseja continuar o envio da Pré-Coleta?",
    });

    /*dialogRef.afterClosed().subscribe(result => {
      if(result) {
        console.log('Sim clicked');
        console.log(result);
        // DO SOMETHING
        return result
      }
    });*/
    dialogRef.componentInstance.changeRef.markForCheck();
    return dialogRef.componentInstance.event;
  }

  confirmarEnvioDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: "Deseja realizar o envio das coletas selecionadas?"
    });

    dialogRef.componentInstance.changeRef.markForCheck();
    return dialogRef.componentInstance.event;

  }

  alertDialog(text: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Você quer alterar esse registro?"
    });

    /*dialogRef.afterClosed().subscribe(result => {
      if(result) {
        console.log('Sim clicked');
        console.log(result);
        // DO SOMETHING
        return result
      }
    });*/
    dialogRef.componentInstance.changeRef.markForCheck();
    return dialogRef.componentInstance.event;

  }

  bannerDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      ariaLabel: 'Banner',
      width: '620px',
      height: '650px',
      data: { banner: 'Atenção!', msg: 'Informativo' },
    });

    dialogRef.componentInstance.changeRef.markForCheck();
    return dialogRef.componentInstance.event;

  }

  confirmarEscopoDialog(dados: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      width: '450px',
      data: dados,
    });
    dialogRef.componentInstance.changeRef.markForCheck();
    return dialogRef.componentInstance.event;
  }

}
