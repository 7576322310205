import { NotificationService } from 'src/app/core/notification/notification-service.service';
import { FormaPagtoService } from 'src/app/services/forma-pagto.service';

import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-pix-payment-dialog',
  templateUrl: './pix-payment-dialog.component.html',
  styleUrls: ['./pix-payment-dialog.component.scss']
})
export class PixPaymentDialogComponent implements OnInit {
  constructor (
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PixPaymentDialogComponent>,
    public changeRef: ChangeDetectorRef,
    private formaPagto: FormaPagtoService,
    private toastr: NotificationService,
  ) { }

  event: EventEmitter<any> = new EventEmitter();
  iFrame: HTMLIFrameElement;

  btnRefreshLock: boolean = false;
  btnCancelLock: boolean = false;
  interruptAwait: boolean = false;

  lancamento: number;
  id_unico: number;

  timeBtnGet: number = 5;
  timeAutoGet: number = 40;

  async ngOnInit() {
    this.iFrame = <HTMLIFrameElement>document.getElementById("IframePix");
    this.lancamento = this.data.idLancamento;
    this.id_unico = this.data.id_unico;
    // let returnPgto = await this.getLanc(); // Busca pelo status de pagamento na tab_voucher_lancamento
    let returnPgto = await this.consultarCobranca(); // Busca pelo status de pagamento no PJBank
    if (!returnPgto) {
      this.autoGet(this.timeAutoGet);
    } else {
      this.lockBtn(true);
      this.pixPago();
    }
  }

  public async clickRefresh() {
    this.interruptAwait = true;
    // let returnPgto = await this.getLanc(); // Busca pelo status de pagamento na tab_voucher_lancamento
    let returnPgto = await this.consultarCobranca(); // Busca pelo status de pagamento no PJBank
    if (!returnPgto) {
      this.btnGet(this.timeBtnGet);
      this.autoGet(this.timeAutoGet);
    } else {
      this.lockBtn(true);
      this.pixPago();
    }
  }

  public clickCancel() {
    this.lockBtn(true);
    this.interruptAwait = true
    this.event.emit(false);
    this.dialogRef.close();
  }

  public autoGet(totalTime: number) {
    const progressBarEl = <HTMLDivElement>document.querySelector(".progress");
    var remainingTime = totalTime;
    (async () => {
      var i = 0;
      while (await new Promise(resolve => setTimeout(() => { resolve(i++) }, 1000)) < 1000) {
        if (!this.interruptAwait) {
          if (remainingTime >= 0) {
            const progress = (100 * remainingTime) / totalTime;
            progressBarEl.style.width = `${progress}%`;
            remainingTime -= 1;
          } else {
            // let returnPgto = await this.getLanc(); // Busca pelo status de pagamento na tab_voucher_lancamento
            let returnPgto = await this.consultarCobranca(); // Busca pelo status de pagamento no PJBank
            if (!returnPgto) {
              this.autoGet(this.timeAutoGet);
              break
            } else {
              this.pixPago();
              break
            }
          }
        } else {
          this.interruptAwait = false;
          break
        }
      }
    })();
  }

  public btnGet(time: number) {
    this.btnRefreshLock = true;
    (async () => {
      var i = 0;
      while (await new Promise(resolve => setTimeout(() => { resolve(i++) }, 1000)) < 100) {
        time -= 1;
        if (time < 0) {
          this.btnRefreshLock = false;
          break
        }
      }
    })();
  }

  public lockBtn(status: boolean) {
    this.btnRefreshLock = status;
    this.btnCancelLock = status;
  }

  public async getLanc() { // Busca pelo status de pagamento na tab_voucher_lancamento. Depende do PJBank sinalizar o pagamento via webhook
    return new Promise((resolve) => {
      this.formaPagto.consultarLancamento(this.lancamento).subscribe(
        (dados) => {
          this.iFrame.src = dados.object.linkBoleto + "?formato=pix";
          this.id_unico = dados.object.id_unico;
          if ((dados.object.valor != null && dados.object.valor_pago != null) && (dados.object.valor_pago == dados.object.valor)) {
            resolve(true);
          } else {
            resolve(false);
          }
        }
      );
    });
  }

  public async consultarCobranca() { // Busca pelo status de pagamento no PJBank. Não depende do webhook
    return new Promise((resolve) => {
      this.formaPagto.consultarCobranca(this.id_unico).subscribe(
        (dados) => {
          let dadosBoleto = dados.object[0];
          console.log(dadosBoleto);
          this.iFrame.src = dadosBoleto.link + "?formato=pix";
          if ((dadosBoleto.valor != null && dadosBoleto.valor_pago != null) && (dadosBoleto.valor_pago == dadosBoleto.valor)) {
            resolve(true);
          } else {
            resolve(false);
          }
        }
      );
    });
  }

  public pixPago() {
    this.toastr.showSuccess("Pix pago com sucesso", "Sucesso");
    this.event.emit(true);
    this.dialogRef.close();
  }
}
