
export class  Users {
    userName: string;
    password: string;
  }

  export class Roles  {
    id: number;
    id_usuario: number;
    role: string;
    create: number;
    delete: number;
    read: number;
    update: number;
    status: number;
  }

  export enum Role {
    ROLES_DEBUGGER_ADMIN = "DebuggerSystem",
    ROLES_SYSTEM_ADMIN = "AdminSystem",
    ROLES_POSTO_ADMIN = "AdminPosto",
    ROLES_POSTO_USER = "ColetorPosto",
    ROLES_POSTO_RECEPTION = "RecepcaoPosto"
  }